/* BASIC STYLES */
.skeleton-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.skeleton {
  background: #ddd;
  border-radius: 5px;
}

.skeleton.label {
  width: 180px;
  height: 21.5px;
  margin-bottom: 8px;
}

.skeleton.title {
  width: 40%;
  height: 34px;
  margin: 60px;
  grid-area: title;
  justify-self: center;
}

.skeleton.button {
  width: 150px;
  height: 45px;
  margin-bottom: 60px;
  background-color: #1CBFA3;
  align-self: flex-end;
  justify-self: flex-end;
  grid-area: button;
}

.skeleton.element {
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
}

.skeleton.saved-invoice {
  width: 100%;
  height: 86px;
}

.skeleton.input {
  height: 45px;
  width: 100%;
  margin-bottom: 20px;
}

.skeleton.header {
  width: 100%;
  height: 80px;
  background-color: #1CBFA3;
  z-index: 20;
  -webkit-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 6px 0px rgb(0,0,0,0.2);
}

.skeleton-stats {
  width: 25%;
  margin-right: 40px;
}

.skeleton.stat {
  width: 100%;
  height: 200px;
}


.skeleton-templates:nth-child(2) {
  margin: 0 60px;
}

.skeleton.template {
  width: 100%;
  height: 250px;
}

.skeleton.invoice {
  width: 100%;
  height: 1000px;
}

.skeleton.controller {
  width: 100%;
  height: 500px;
}

/* ANIMATION EFFECT */
.shimmer-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;
}

.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255,255,255,0.2);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255,255,255,0.05);
}

@keyframes loading {
  0% { transform: translateX(-150%); }
  50% { transform: translateX(-60%); }
  100% { transform: translateX(150%); }
}


/* MEDIA QUERIES */
@media only screen and (max-width: 950px) {
  .skeleton.button {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 900px) {
  .skeleton-stats {
    width: 47%;
    margin-right: 0;
  }

  .skeleton-stats:nth-child(2) {
    margin-right: 0;
  }
  .skeleton-stats:nth-child(1) {
    margin-right: 6%;
  }
  .skeleton-stats:nth-child(3) {
    margin-right: 6%;
  }
  .skeleton-stats:nth-child(1), .stat:nth-child(2) {
    margin-bottom: 6%;
  }
}

@media only screen and (max-width: 737px) {
  .skeleton-templates:nth-child(2) {
    margin: 60px 0;
  }
}
